/**
 * This file kicks off VITEJS tasks to minify and compile CSS and JS.
 */
// CSS imports
import './css/main.css'

// JS imports

import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import intersect from '@alpinejs/intersect'
import loadComponents from "./js/obaia-components.js"

const initApp = async () => {
    await loadComponents()
    Alpine.plugin(collapse)
    Alpine.plugin(intersect)
    Alpine.start()
}
initApp()

// * END OF FILE
// Obaia branding starts here
let obaiaSvgIcon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 146 146"><defs><linearGradient id="a" x1="72.312%" x2="28.214%" y1="95.074%" y2="4.881%"><stop offset="0%" stop-color="#FFC000"/><stop offset="49.708%" stop-color="#FF1493"/><stop offset="100%" stop-color="#1E90FF"/></linearGradient></defs><path fill="url(#a)" fill-rule="evenodd" d="M102.997 30c16.089 0 30.962 5.205 43.027 14.023l.387.292a8 8 0 0 1 2.359 2.862l.96 1.96a354579.744 354579.744 0 0 1 25.562 52.42 7 7 0 0 1 .707 3.16l-.002.152-.003.14c-1.064 39.388-33.353 70.99-72.997 70.99C62.682 176 30 143.317 30 103c0-40.316 32.682-73 72.997-73Zm0 25c-26.509 0-47.998 21.49-47.998 48s21.49 48 47.998 48 47.998-21.49 47.998-48-21.49-48-47.998-48Z" transform="translate(-30 -30)"/></svg>'
let obaiaSvgLogo = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 834 206"><g fill="#000" fill-rule="evenodd"><rect width="28" height="146" x="574" y="30" rx="4"/><path d="M445.106 30h15.669a16 16 0 0 1 14.388 9.002L539.7 171.688A3 3 0 0 1 537 176h-14.106a16 16 0 0 1-14.39-9.005l-65.198-134.12a2 2 0 0 1 1.8-2.875Zm-4.09 60.838-40.228 82.908a4 4 0 0 1-3.599 2.254h-23.19a2 2 0 0 1-1.799-2.874l53.465-110.024a2 2 0 0 1 3.599.002l11.753 24.243a4 4 0 0 1-.001 3.491ZM708.106 30h15.669a16 16 0 0 1 14.388 9.002L802.7 171.688A3 3 0 0 1 800 176h-14.106a16 16 0 0 1-14.39-9.005l-65.198-134.12a2 2 0 0 1 1.8-2.875Zm-4.09 60.838-40.228 82.908a4 4 0 0 1-3.599 2.254h-23.19a2 2 0 0 1-1.799-2.874l53.465-110.024a2 2 0 0 1 3.599.002l11.753 24.243a4 4 0 0 1-.001 3.491ZM292.689 30c29.63 0 41.595 25 41.595 40.994 0 10.673-3.989 18.803-11.965 24.39C335.439 105.794 342 117.667 342 131c0 20-7.716 45-49.311 45H231.89a4 4 0 0 1-3.64-2.344l-21.892-48.145a4 4 0 0 1-.359-1.656V91.003a4 4 0 0 1 4-4h82.689c16.755 0 17.258-13.054 17.273-15.786v-.41c-.015-2.373-.518-15.809-17.273-15.809H210a4 4 0 0 1-4-4V34a4 4 0 0 1 4-4h82.689Zm1.041 81h-59.843a4 4 0 0 0-4 4v7.17a4 4 0 0 0 .36 1.66l11.32 24.83a4 4 0 0 0 3.64 2.34h48.523c3.181 0 6.213-.282 9.096-.847s5.418-1.582 7.605-3.051c2.187-1.47 3.976-3.503 5.368-6.102 1.392-2.599 2.088-6.045 2.088-10.339 0-6.554-1.89-11.469-5.667-14.746-3.777-3.277-9.94-4.915-18.49-4.915ZM103 30c16.09 0 30.963 5.205 43.03 14.023l.386.292a8 8 0 0 1 2.359 2.862l.96 1.96a354704.004 354704.004 0 0 1 25.564 52.42 7 7 0 0 1 .707 3.16l-.003.152-.003.14c-1.063 39.388-33.354 70.99-73 70.99-40.317 0-73-32.683-73-73 0-40.316 32.683-73 73-73Zm0 25c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48Z"/></g></svg>'
let encodedSvg = encodeURIComponent(obaiaSvgLogo)
let backgroundImage = 'url("data:image/svg+xml;charset=utf-8,' + encodedSvg + '")'
const rocket = String.fromCodePoint(0x0001F680)
console.log('%c ', 'font-size: 0px; background-image: ' + backgroundImage + '; background-size: contain; background-repeat: no-repeat; padding: 42px 200px;')
console.log(`%c
` + rocket + ` Made with a keyboard by:

Obaia Webdevelopment
site: https://obaia.dev
info: info@obaia.dev

`, `font-size: 20px; font-family: 'Space Mono', monospace;`)