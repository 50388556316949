// import {initPopoverGroup} from "./components/base.js";

window.obaia = window.obaia || {}
window.obaia.Components = window.obaia.Components || {}

console.log('obaia components power')

const components = {
    popover: () => import('./components/popover.js'),
    popoverGroup: () => import('./components/popovergroup.js'),
    swiper: () => import('./components/obaia-swiper.js'),
    scrollspy: () => import('./components/scrollspy.js'),
    // menu: () => import('./components/menu.js'),
};

const loadComponents = async () => {
    await Promise.all(Object.entries(components).map(async ([name, loader]) => {
        try {
            const module = await loader()
            const initFunctionName = `init${name.charAt(0).toUpperCase() + name.slice(1)}`
            if (typeof module[initFunctionName] === 'function') {
                window.obaia.Components[name] = module[initFunctionName]
            }
        } catch (error) {
            console.error(`Error loading component ${name}:`, error)
        }
    }))
}

export default loadComponents